import React, { FunctionComponent, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { ArrowDownIcon } from '../../icons'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  title: string | ReactNode
  path: string
  arrowColor?: string
  triggerBtnClass?: string
  children?: ReactNode
  isOpen: boolean
  setIsOpen: (id: string) => void
}

export const DropDown: FunctionComponent<Props> = ({
  title,
  path,
  arrowColor = 'black',
  triggerBtnClass = '',
  children,
  isOpen,
  setIsOpen
}) => {
  const { pathname } = useLocation()
  const isActivePath = Boolean(pathname.includes(`${path}/`))

  const dropDownMenuVariants = {
    initial: { opacity: 0, translateY: '10%', translateX: '-25%', zIndex: '-9' },
    animate: { opacity: 1, translateY: '0%', translateX: '-25%', zIndex: '2' }
  }

  return (
    <div className="relative h-full" onMouseLeave={() => setIsOpen('')}>
      <button
        onClick={() => setIsOpen(path)}
        className={`cursor-pointer flex justify-center items-center w-full h-full transition-colors ${
          isActivePath ? 'bg-[rgb(255,255,255)]/10' : 'bg-transparent'
        } ${triggerBtnClass ? triggerBtnClass : 'hover:bg-[rgb(255,255,255)]/10'}`}
      >
        <span className="block relative flex items-center justify-center">
          <span className="mr-md">{title}</span>
          <ArrowDownIcon color={arrowColor} />
        </span>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={dropDownMenuVariants.initial}
            animate={dropDownMenuVariants.animate}
            exit={dropDownMenuVariants.initial}
            className="absolute bg-white rounded-half p-lg shadow-2xl min-w-screen-sm transform -translate-x-1/4"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
