import React from 'react'
import { Outlet } from 'react-router-dom'
import { WebhookIcon } from '../../../icons'

export const WebhooksEmptyState = () => {
  return (
    <div className="grid grid-cols-12 gap-md items-start mt-md">
      <div className="col-span-6 col-start-4 card p-lg">
        <div className="py-xl space-y-md flex flex-col items-center -rotate-[5deg]">
          <WebhookIcon size="4rem" color="var(--colors-storm)" />
        </div>

        <Outlet />
      </div>
    </div>
  )
}
