import React, { FunctionComponent } from 'react'

type Props = {
  isActive: boolean
}

export const WebhookStateIndicator: FunctionComponent<Props> = ({ isActive }) => {
  const status = isActive ? 'active' : 'not active'

  return (
    <span role="img" className="relative" aria-label={`Status ${status}`}>
      {isActive ? (
        <span className="peer flex w-1 h-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded bg-green-transparent" />
          <span className="relative inline-flex rounded w-1 h-1 bg-green-transparent" />
        </span>
      ) : (
        <span className="peer flex w-1 h-1 rounded bg-dust" />
      )}
    </span>
  )
}
