import React, { FunctionComponent } from 'react'

type Props = {
  node: { children: string[] }
}

export const InlineCodeRenderer: FunctionComponent<Props> = ({ node }) => {
  const text = node.children

  return (
    <code className="bg-fog text-[0.9rem] font-mono rounded-half p-[0.15rem] before:content-none after:content-none">
      {text}
    </code>
  )
}
