import { redirect } from 'react-router-dom'
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom'
import { authenticatedFetchData } from '../../../../services/fetch'
import { API_HOST } from '../../../../lib/environment'
import { Webhook } from '../../../../types'
import { handleRequestError } from '../../../../lib/httpHelpers'
import { ampli } from '../../../../ampli'

export const webhooksLoader = ({ params }: LoaderFunctionArgs) => {
  return Promise.all([getWebhooksAction((params.environment as string) || 'sandbox'), getWebhookEventTypes()]).then(
    ([webhooks, webhookEventTypes]) => ({ webhooks, webhookEventTypes })
  )
}

export const getWebhooksAction = (environment: string) =>
  authenticatedFetchData(`${API_HOST}/webhooks?environment=${environment}`, {
    method: 'GET'
  }).run()

export const webhookLoader = ({ params }: LoaderFunctionArgs) => {
  const { webhookId, environment } = params

  return authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}?environment=${environment}`, {
    method: 'GET'
  }).run()
}

export const getWebhookEventTypes = () => authenticatedFetchData(`${API_HOST}/webhook-events`).run()

export const createWebhookAction = async ({ request, params }: ActionFunctionArgs) => {
  const { environment } = params as { environment: string }

  const formData = await request.formData()
  const webhookFormValues = Object.fromEntries(formData)
  const events = formData.getAll('events') as string[]

  const webhook: Webhook = {
    url: webhookFormValues.url as string,
    active: Boolean(webhookFormValues.active),
    events: events || []
  }

  if (!webhook.events || webhook.events?.length === 0) {
    return { error: 'Please select at least one event' }
  }

  const response = await authenticatedFetchData(`${API_HOST}/webhooks?environment=${environment}`, {
    method: 'POST',
    body: JSON.stringify(webhook)
  })
    .run()
    .catch(handleRequestError)

  if (response.error) {
    return response
  }

  ampli.addWebhook({ environment })

  return { success: response }
}

export const updateWebhookAction = async ({ request, params }: ActionFunctionArgs) => {
  const { environment, webhookId } = params as { environment: string; webhookId: string }

  const formData = await request.formData()
  const webhookFormValues = Object.fromEntries(formData)
  const events = formData.getAll('events') as string[]

  const webhook: Webhook = {
    id: webhookId,
    url: webhookFormValues.url as string,
    active: Boolean(webhookFormValues.active),
    events: events || []
  }

  if (!webhook.events || webhook.events?.length === 0) {
    return { error: 'Please select at least one event' }
  }

  const response = await authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}?environment=${environment}`, {
    method: 'PUT',
    body: JSON.stringify(webhook)
  })
    .run()
    .catch(handleRequestError)

  if (response.error) {
    return response
  }

  ampli.editWebhook({ environment })

  return redirect(`/account/${environment}/webhooks/${webhookId}`)
}

export const deleteWebhookAction = async ({ params }: LoaderFunctionArgs) => {
  const { webhookId, environment } = params as { webhookId: string; environment: string }

  await authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}?environment=${environment}`, {
    method: 'DELETE'
  }).run()

  ampli.deleteWebhook({ environment })

  return redirect(`/account/${environment}/webhooks`)
}

export const webhookHistoryLoader = (args: LoaderFunctionArgs) => {
  const { params, request } = args

  const requestUrl = new URL(request.url)
  const searchParams = new URLSearchParams(requestUrl.search)
  const status = searchParams.get('status')
  const pageNum = searchParams.get('page') || '0'

  const { webhookId, environment } = params

  const url = new URL(`${API_HOST}/webhooks/${webhookId}/history`)
  url.searchParams.append('page', pageNum)
  url.searchParams.append('environment', environment as string)

  if (status) {
    url.searchParams.append('status', status.toUpperCase())
  }

  return authenticatedFetchData(url.toString(), {
    method: 'GET'
  }).run()
}

export const webhookNotificationLoader = (args: LoaderFunctionArgs) => {
  const { params } = args
  const { webhookId, environment } = params

  return authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}/notification?environment=${environment}`, {
    method: 'GET'
  }).run()
}

export const webhookNotificationActions = async (args: ActionFunctionArgs) => {
  const { request } = args
  const { environment, webhookId } = args.params

  const formData = await request.formData()
  const formValues = Object.fromEntries(formData)

  const response = await authenticatedFetchData(
    `${API_HOST}/webhooks/${webhookId}/notification?environment=${environment}`,
    {
      method: request.method.toUpperCase(),
      body: JSON.stringify(formValues.email)
    }
  )
    .run()
    .catch(handleRequestError)

  if (response.error) {
    return response
  }

  if (request.method === 'DELETE') {
    ampli.deleteWebhookNotificationEmail()
  } else {
    ampli.addWebhookNotificationEmail()
  }

  return { message: 'success' }
}

export const activateWebhookAction = async (args: ActionFunctionArgs) => {
  const { environment, webhookId } = args.params

  const webhook = await webhookLoader(args)

  return authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}?environment=${environment}`, {
    method: 'PUT',
    body: JSON.stringify({ ...webhook, active: true })
  }).run()
}
