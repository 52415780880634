import React, { FunctionComponent } from 'react'

const alertTypeClassMap: { [key: string]: string } = {
  info: 'info',
  note: 'note',
  warning: 'warning',
  caution: 'warning',
  danger: 'danger'
}

type Props = {
  node: { type: string; children?: React.ReactNode[] }
}

export const Admonition: FunctionComponent<Props> = ({ node }) => {
  const { type, children } = node
  return <div className={`alert alert-${alertTypeClassMap[type]} not-prose mb-md`}>{children}</div>
}
