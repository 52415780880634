import React, { FunctionComponent, ReactNode, useState } from 'react'
import { ampli } from '../../../../ampli'

const noOp = () => {}

type Props = {
  label: string
  textToCopy: string
  children?: ReactNode
}

export const ClickToCopySection: FunctionComponent<Props> = ({ label, textToCopy, children }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyPayload = () => {
    ampli.copyText({ label })

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)

        setTimeout(() => {
          setIsCopied(false)
        }, 3000)
      })
      .catch(noOp)
  }

  return (
    <button
      onClick={copyPayload}
      className="group relative rounded-half bg-grey-light text-left w-full transition hover:bg-fog"
    >
      <span className="block">
        {isCopied ? (
          <span className="absolute -top-md left-1/2 -translate-x-1/2 text-white text-sm bg-black-transparent p-sm rounded-half font-bold">
            Copied!
          </span>
        ) : (
          <span className="absolute -top-md left-1/2 -translate-x-1/2 text-white text-sm bg-black-transparent p-sm rounded-half font-bold opacity-0 group-hover:opacity-100">
            Click to copy
          </span>
        )}

        {children}
      </span>
    </button>
  )
}
