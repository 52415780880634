import React, { FunctionComponent } from 'react'
import tomraArrow from '../../images/src_images_tomra-arrow.png'
import { NavLink } from 'react-router-dom'

type Props = {
  children?: React.ReactNode
}

export const NavigationBar: FunctionComponent<Props> = ({ children }) => {
  return (
    <nav role="navigation" className="navbar relative lg:fixed px-md nav-bar-height z-[2]">
      <div className="w-full nav-bar-height mx-auto lg:px-md grid grid-cols-12 items-center max-w-screen-2xl">
        <div className="flex items-center col-span-8 lg:col-span-3">
          <img src={tomraArrow} className="h-xl mr-md" alt="TOMRA logo" />

          <NavLink className="font-bold" to="/">
            TOMRA Collection Developer
          </NavLink>
        </div>

        {children}
      </div>
    </nav>
  )
}
