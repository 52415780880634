import React, { FunctionComponent, useState } from 'react'
import { authenticatedFetchData } from '../../../services/fetch'
import { logError } from '@tomra/datadog-browser-logging'
import { nanoid } from 'nanoid'
import { AnimatePresence, motion } from 'framer-motion'
import { expandAnimation } from '../animationVariants'

const noOp = () => {}

type Props = {
  node: { children: string[]; enable: string; method: string; target: string }
}

export const ButtonRenderer: FunctionComponent<Props> = ({ node }) => {
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'success' | 'failure'>('idle')
  const [error, setError] = useState('')

  const { target, method, enable, children } = node
  const text = children

  const buttonIsEnabled = enable === 'true'

  const buttonAction = () => {
    setRequestStatus('pending')

    authenticatedFetchData(target, { method })
      .run()
      .then(() => {
        setRequestStatus('success')
        setTimeout(() => setRequestStatus('idle'), 3000)
      })
      .catch(error => {
        if (error.status === 429) {
          setError('Too many request. Please wait a few minutes before you try again.')
        } else {
          logError(new Error('Unable to perform button action'), error)
          setError('Something went wrong. Please try again later or contact support.')
        }

        setRequestStatus('failure')
        setTimeout(() => setRequestStatus('idle'), 3000)
      })
  }

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <AnimatePresence key={nanoid()}>
        {requestStatus === 'success' ? (
          <motion.div {...expandAnimation} className="font-bold text-green-dark">
            All done!
          </motion.div>
        ) : requestStatus === 'failure' ? (
          <motion.div className="font-bold text-red-dark" {...expandAnimation}>
            {error}
          </motion.div>
        ) : null}
      </AnimatePresence>

      <button
        className="btn flex items-center mt-md"
        onClick={buttonIsEnabled ? buttonAction : noOp}
        aria-disabled={!buttonIsEnabled}
      >
        {requestStatus === 'pending' ? <span role="progressbar" className="loadingSpinner" /> : text}
      </button>

      {!buttonIsEnabled && (
        <small className="mt-sm font-bold">You need a license for this product to generate test data</small>
      )}
    </div>
  )
}
