import React, { FunctionComponent } from 'react'

type Props = {
  isOpen: boolean
  children: React.ReactNode
}

export const SlideDownMenu: FunctionComponent<Props> = ({ isOpen, children }) => {
  return (
    <div
      className="absolute top-0 w-full md:w-1/2 lg:w-1/4 right-0 bg-midnight shadow-lg"
      style={{
        padding: 'var(--navigationBarHeight) var(--spacing-lg) var(--spacing-lg)',
        transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
        transition: `transform 0.5s cubic-bezier(0, 1, 0.5, 1)`,
        zIndex: 1
      }}
    >
      {children}
    </div>
  )
}
