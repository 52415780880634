import React, { FunctionComponent, useState } from 'react'
import { ApiRequestBody } from './ApiRequestBody'
import { ApiParameters } from './ApiParameters'
import { MinusIcon, PlusIcon } from '../../../icons'
import { ApiResponses } from './ApiResponses'
import { useNavigate, useLocation } from 'react-router-dom'
import { OpenAPIV3_1 } from 'openapi-types'
import { createIdFromString, isOperation } from '../spec-helpers'
import { MarkdownContent } from '../../../shared/MarkdownContent'
import { ErrorBoundary } from '../../../shared/ErrorBoundary'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { expandAnimation } from '../../../shared/animationVariants'
import { ampli } from '../../../../ampli'

const operationColorMap: { [key: string]: string } = {
  get: 'bg-blue-dark',
  post: 'bg-green-dark',
  put: 'bg-purple',
  delete: 'bg-red-dark'
}

type Props = {
  tag: string
  pathsByTags: { [key: string]: OpenAPIV3_1.PathItemObject }
}

export const ApiPaths: FunctionComponent<Props> = ({ tag, pathsByTags }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = useState<string | null>(null)

  const paths = pathsByTags[tag]
  const pathNames = Object.keys(paths)

  return (
    <ErrorBoundary>
      <LayoutGroup key={`path-${tag}`}>
        {pathNames.map(name => {
          const path = paths[name as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.PathItemObject
          const operationNames = Object.keys(path).filter(isOperation)

          return operationNames.map(operationName => {
            const id = createIdFromString(`${tag}-${operationName}-${name}`)
            const isOpen = open === id || location.hash === `#${id}`

            const toggleSection = () => {
              if (!isOpen) {
                ampli.openApiSpecSection({ path: `${operationName.toUpperCase()}: ${name}` })
                setOpen(id)
                navigate(`#${id}`)
              } else {
                navigate(location.pathname)
                setOpen(null)
              }
            }

            const operation = path[operationName as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.OperationObject
            const { parameters, requestBody, responses } = operation

            const description = operation.description ?? path.description

            return (
              <div id={id} className="card mb-lg scroll-margin-top-lg" key={id}>
                <button
                  className="w-full p-md rounded-half focus:ring-4 focus:ring-wind focus:outline-none"
                  onClick={toggleSection}
                >
                  <span className="flex items-center justify-between">
                    <span className="flex space-x-md items-center">
                      <div
                        className={`inline-bloc p-sm w-[5rem] text-center uppercase font-bold rounded-half text-white ${operationColorMap[operationName]}`}
                      >
                        {operationName}
                      </div>
                      <pre className="m-0">{name}</pre>
                    </span>

                    <span
                      className={isOpen ? 'transition transform -rotate-180' : 'transition transform -rotate-0'}
                      aria-hidden="true"
                    >
                      {isOpen ? (
                        <MinusIcon width="1.5rem" height="1.5rem" />
                      ) : (
                        <PlusIcon width="1.25em" height="1.5rem" />
                      )}
                    </span>
                  </span>
                </button>

                <AnimatePresence key={`path-${name}`}>
                  {isOpen && (
                    <motion.div className="px-md" {...expandAnimation}>
                      {description && (
                        <div className="prose mb-md">
                          <MarkdownContent markdown={description} />
                        </div>
                      )}

                      <div className="flex flex-col space-y-xl pb-md">
                        <ApiParameters parameters={parameters as OpenAPIV3_1.ParameterObject[]} />

                        <ApiRequestBody requestBody={requestBody as OpenAPIV3_1.RequestBodyObject} />

                        <ApiResponses responses={responses} />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )
          })
        })}
      </LayoutGroup>
    </ErrorBoundary>
  )
}
