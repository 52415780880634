import React, { useEffect, useRef } from 'react'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { DeleteIcon } from '../../../../icons'
import { FetcherError } from '../../common/FetcherError'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { expandAnimation } from '../../../../shared/animationVariants'

export const WebhookNotifications = () => {
  const registeredEmails = useLoaderData() as string[]
  const createFormRef = useRef<HTMLFormElement>(null)

  const deleteFetcher = useFetcher()
  const createFetcher = useFetcher()

  useEffect(() => {
    if (createFetcher.state === 'idle' && createFormRef.current) {
      createFormRef.current.reset()
    }
  }, [createFetcher.state])

  return (
    <LayoutGroup>
      <motion.div className="card p-lg flex flex-col">
        <h2 className="text-md">E-mail notifications</h2>

        {createFetcher.state === 'submitting' || createFetcher.state === 'loading' ? (
          <div className="py-lg">
            <div className="loadingSpinner" />
          </div>
        ) : registeredEmails.length === 0 ? (
          <div className="my-md">Add e-mails to this list to receive notifications on webhook delivery issues.</div>
        ) : null}

        <FetcherError fetcher={deleteFetcher} />

        <AnimatePresence key="emailList">
          {registeredEmails.length > 0 && (
            <motion.ul className="flex flex-col py-lg" {...expandAnimation}>
              {registeredEmails.map(email => {
                const data = deleteFetcher.formData
                  ? Object.fromEntries(deleteFetcher.formData as FormData)
                  : { email: '' }
                const isDeleting =
                  (email === data.email && deleteFetcher.state === 'submitting') || deleteFetcher.state === 'loading'

                return (
                  <motion.li
                    key={`li-${email}`}
                    className="flex items-center justify-between space-x-md hover:bg-grey-light"
                    aria-label={email}
                  >
                    {isDeleting ? (
                      <div key={`d-${email}`} className="loadingSpinner text-sm my-md" />
                    ) : (
                      <>
                        <span>{email}</span>

                        <div>
                          <deleteFetcher.Form method="delete" className="flex justify-between">
                            <input type="hidden" name="email" value={email} />
                            <button type="submit" className="btn btn-icon" aria-label={`Delete ${email}`}>
                              <DeleteIcon width="1.5rem" height="1.5rem" color="var(--colors-red)" />
                            </button>
                          </deleteFetcher.Form>
                        </div>
                      </>
                    )}
                  </motion.li>
                )
              })}
            </motion.ul>
          )}
        </AnimatePresence>

        <createFetcher.Form ref={createFormRef} method="post" className="flex items-end justify-between space-x-md">
          <div className="flex-1">
            <label htmlFor="email" className="label">
              E-mail
            </label>
            <input id="email" type="email" name="email" />
          </div>
          <button type="submit" className="btn col-span-2">
            Add
          </button>
        </createFetcher.Form>

        <FetcherError fetcher={createFetcher} />
      </motion.div>
    </LayoutGroup>
  )
}
