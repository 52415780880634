import React from 'react'
import { CopyTextField } from '../common/CopyTextField'
import { OAuthClientSecret } from './OAuthClientSecret'
import { Link, useLoaderData, useParams } from 'react-router-dom'
import { OAuthClient } from '../../../../types'

export const OAuthClientDetail = () => {
  const { environment } = useParams()
  const client = useLoaderData() as OAuthClient

  return (
    <React.Fragment>
      <h2 className="text-lg mb-md">{client.name}</h2>

      <div>Client ID</div>
      <CopyTextField label="Client ID" value={client.id} />

      <div className="mt-sm">Client Secret</div>
      <OAuthClientSecret client={client} />

      <Link
        to={`/account/${environment}/oauth-clients/${client.id}/delete`}
        aria-label={`Delete "${client.name}"`}
        className="btn border-red text-red mt-md mx-auto inline-block"
      >
        Delete &quot;{client.name}&quot;
      </Link>
    </React.Fragment>
  )
}
