import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { LockIcon } from '../../../icons'

type Props = {
  securitySchemes: { [key: string]: OpenAPIV3_1.SecuritySchemeObject }
}

export const ApiAuthentication: FunctionComponent<Props> = ({ securitySchemes }) => {
  if (!securitySchemes) {
    return null
  }

  const schemeKeys = Object.keys(securitySchemes)

  return (
    <div className="mt-md mb-lg">
      <h2 id="authentication" className="mb-sm text-lg">
        Authentication
      </h2>

      <ul className="flex flex-col space-y-md">
        {schemeKeys.map((key: string) => {
          const scheme = securitySchemes[key]

          return scheme.type === 'apiKey' ? (
            <li key={`li-apikey`} aria-label="API Key" className="card p-md flex items-start space-x-sm">
              <LockIcon />

              <div>
                <div className="font-bold">API Key {scheme.in}</div>
                <pre className="bg-grey-light p-xs inline">{scheme.name}</pre>
              </div>
            </li>
          ) : scheme.type === 'http' ? (
            <li
              key={`li-http-scheme`}
              aria-label={`${scheme.type} ${key}`}
              className="card p-md flex items-start space-x-sm"
            >
              <LockIcon />

              <div>
                <div className="capitalize font-bold">
                  {scheme.type} {key}
                </div>

                {scheme?.bearerFormat && (
                  <span>
                    <span>Bearer format: </span>
                    <pre className="bg-grey-light p-xs inline">{scheme.bearerFormat}</pre>
                  </span>
                )}
              </div>
            </li>
          ) : null
        })}
      </ul>
    </div>
  )
}
