import React, { FunctionComponent } from 'react'
import { MenuItem, VerticalMenu } from '../../../shared/VerticalMenu'
import { generateApiDocMenuItems } from '../spec-helpers'
import { ArrowRightIcon } from '../../../icons'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  pathTags: string[]
  pathsByTags: Record<string, OpenAPIV3_1.PathItemObject>
  name: string
  webhookTags: string[]
  webhookByTags: Record<string, OpenAPIV3_1.PathItemObject>
}

export const ApiDocMenu: FunctionComponent<Props> = ({ pathTags, pathsByTags, name, webhookTags, webhookByTags }) => {
  const pathMenuItems: MenuItem[] = generateApiDocMenuItems(name, pathTags, pathsByTags)
  const webhookMenuItems: MenuItem[] = generateApiDocMenuItems(name, webhookTags, webhookByTags)

  return (
    <div className="stick-to-top-on-scroll">
      <div className="mb-sm pl-md flex items-center space-x-sm">
        <span className="text-sm font-bold">API</span>
        <ArrowRightIcon width="0.7rem" height="0.7rem" />
        <span className="font-bold text-sm">{name}</span>
      </div>

      <VerticalMenu menuItems={pathMenuItems} />
      <VerticalMenu menuItems={webhookMenuItems} />
    </div>
  )
}
