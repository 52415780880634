import React, { FunctionComponent, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigation } from 'react-router-dom'
import { AnimatedHamburgerIcon } from '../icons/AnimatedHamburgerIcon'
import { ApiProductsData, RequestStatus } from '../../types'
import { SlideDownMenu } from './SlideDownMenu'

type Props = {
  apiProducts: ApiProductsData
  logoutStatus: RequestStatus
  hasAccess: boolean
  logout: () => void
}

export const SmallScreenNavigation: FunctionComponent<Props> = ({ apiProducts, logoutStatus, hasAccess, logout }) => {
  const { pathname } = useLocation()
  const { state } = useNavigation()
  const [isOpen, setIsOpen] = useState(false)

  const navLinkClasses = `block p-sm rounded-half transition-colors hover:bg-[rgb(255,255,255)]/10`

  useEffect(() => {
    setIsOpen(false)
  }, [state])

  return (
    <React.Fragment>
      <div className="col-span-4 col-start-9">
        <button
          aria-label="Open menu"
          className="relative btn btn-icon w-full flex justify-end z-[2]"
          onClick={() => setIsOpen(!isOpen)}
        >
          <AnimatedHamburgerIcon isOpen={isOpen} />
        </button>
      </div>

      <SlideDownMenu isOpen={isOpen}>
        {hasAccess && apiProducts ? (
          <React.Fragment>
            <h2 className="text-sm font-bold mb-md uppercase tracking-wider">Getting started</h2>
            <ul className="mb-xl">
              <li>
                <NavLink to="/" className={`${navLinkClasses} ${pathname === '/' ? `bg-[rgb(255,255,255)]/20` : ``}`}>
                  Getting started
                </NavLink>
              </li>
            </ul>

            <h2 className="text-sm font-bold mb-md uppercase tracking-wider">Products</h2>
            {apiProducts.products.length > 0 ? (
              <ul className="mb-xl">
                {apiProducts.products.map(product => {
                  const path = `/product/${product.id}`

                  return (
                    <li key={product.id}>
                      <NavLink
                        to={path}
                        className={`${navLinkClasses} ${pathname === path ? `bg-[rgb(255,255,255)]/20` : ``}`}
                      >
                        {product.name}
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <div className="mb-lg text-yellow">You don&apos;t have access to any products at the moment.</div>
            )}

            <h2 className="text-sm font-bold mb-md uppercase tracking-wider">APIs</h2>
            {apiProducts.apis.length > 0 ? (
              <ul className="mb-xl">
                {apiProducts.apis.map(api => {
                  const path = `/api/${api.id}`

                  return (
                    <li key={api.id}>
                      <NavLink
                        to={path}
                        className={`${navLinkClasses} ${pathname === path ? `bg-[rgb(255,255,255)]/20` : ``}`}
                      >
                        {api.name}
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <div className="mb-lg text-yellow">You don&apos;t have access to any APIs at the moment.</div>
            )}
          </React.Fragment>
        ) : null}

        <ul className="mb-xl">
          <li>
            <NavLink to="/account" className={navLinkClasses}>
              Account
            </NavLink>
          </li>
        </ul>

        <button className="btn w-full" disabled={logoutStatus === 'pending'} onClick={logout}>
          {logoutStatus === 'pending' ? <span role="progressbar" className="loadingSpinner" /> : 'Log out'}
        </button>
      </SlideDownMenu>
    </React.Fragment>
  )
}
