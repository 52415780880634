import React from 'react'
import { createRoot } from 'react-dom/client'
import '@tomra/design-system/src/config/tailwind.config.css'
import './style.css'
import { initAuthStore } from './lib/authentication'
import { UnAuthenticatedAppRoutes } from './components/UnAuthenticatedAppRoutes'
import { AuthenticatedAppRoutes } from './components/AuthenticatedAppRoutes'
import { getKcEnvironmentForHost } from './lib/kc-environments'
import { ampli } from './ampli'
import { AMPLITUDE_CONFIG, DATADOG_CONFIG, ENVIRONMENT, isLocalhost, STAGE, VERSION } from './lib/environment'
import { initDatadog } from '@tomra/datadog-browser-logging'

const kcHostForEnvironment = getKcEnvironmentForHost(window.location.host)

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

initDatadog({
  ...DATADOG_CONFIG,
  version: VERSION || 'unknown-version',
  env: `${ENVIRONMENT}.${STAGE}` || 'unknown-environment'
})

ampli.load({
  disabled: isLocalhost,
  client: {
    apiKey: AMPLITUDE_CONFIG.apiKey,
    configuration: {
      defaultTracking: {
        attribution: false,
        pageViews: true,
        sessions: true,
        formInteractions: true,
        fileDownloads: false
      },
      trackingOptions: { ipAddress: false }
    }
  }
})

initAuthStore(kcHostForEnvironment)
  .then(() => root.render(<AuthenticatedAppRoutes />))
  .catch(() => root.render(<UnAuthenticatedAppRoutes />))
