import React, { useState } from 'react'
import { SlideDownMenu } from '../../navigation/SlideDownMenu'
import { authStore, initAuthStore } from '../../../lib/authentication'
import {
  getAvailableKcEnvironments,
  getKcEnvironmentForHostRegion,
  getRedirectUrisForHost
} from '../../../lib/kc-environments'

const kcEnvironments = getAvailableKcEnvironments(window.location.host)

export const RegionSelector = () => {
  const [authState, setAuthState] = useState('')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const loginToRegion = (region: AuthRegion) => {
    setAuthState(`Logging you in...`)

    const host = window.location.host
    const kcHost = getKcEnvironmentForHostRegion(host, region)
    const redirectUri = getRedirectUrisForHost(host)[region]

    return initAuthStore(kcHost, redirectUri).catch(() => authStore.login())
  }

  return (
    <div className="flex items-center justify-end col-span-4 md:col-span-2 md:col-start-11">
      <button
        className={`btn flex items-center justify-center relative transition-colors z-[2] ${
          isOpen ? 'text-white bg-midnight' : 'text-black bg-white'
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen ? authState ? <div className="loadingSpinner" /> : 'Sign in' : 'Close'}
        {authState && <span className="sr-only">{authState}</span>}
      </button>
      <SlideDownMenu isOpen={isOpen}>
        <h2 className="text-lg font-bold text-center mb-md">Select region</h2>
        <ul>
          {kcEnvironments.map(region => (
            <li key={region} className="mb-md">
              <button className="btn w-full" onClick={() => loginToRegion(region as AuthRegion)}>
                {region}
              </button>
            </li>
          ))}
        </ul>
      </SlideDownMenu>
    </div>
  )
}
