import React, { FunctionComponent } from 'react'

type Props = {
  node: { children: any[] }
}

export const CodeBlockRenderer: FunctionComponent<Props> = ({ node }) => {
  const code = node.children[0].children[0].value

  return (
    <pre className="block bg-[#1F2937] p-md rounded-half">
      <code className="tracking-wide leading-6 text-[0.9rem] text-white font-bold font-mono">{code}</code>
    </pre>
  )
}
