import { API_HOST } from '../lib/environment'
import { authenticatedFetchData } from './fetch'

export const getAccount = () => authenticatedFetchData(`${API_HOST}/management`).run()

export const retryWebhookDelivery = (webhookId: string, eventId: string, environment: string) => {
  return authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}/event/${eventId}/retry?environment=${environment}`, {
    method: 'POST',
    body: JSON.stringify({})
  }).run()
}
