import { Notice } from './Notice'
import { ErrorIcon } from '../icons'
import React from 'react'

export const UnknownError = () => {
  return (
    <div className="md:mt-[var(--navigationBarHeight)] py-lg">
      <Notice icon={<ErrorIcon size="4rem" color="var(--colors-orange)" />} title="Something went wrong">
        <p>Our technical team has been notified.</p>
      </Notice>
    </div>
  )
}
