import React from 'react'
import { hasDeveloperPortalLicense } from '../lib/authentication'
import { Outlet, useLoaderData, useLocation, useNavigation } from 'react-router-dom'
import { AuthenticatedNavBar } from './navigation/AuthenticatedNavBar'
import { RequestAccessPage } from './RequestAccessPage'
import { AcceptTermsAndConditionsDialog } from './terms-and-conditions/AcceptTermsAndConditionsDialog'

export const AuthenticatedAppLayout = () => {
  const hasAccess = hasDeveloperPortalLicense()
  const data = useLoaderData()
  const { pathname } = useLocation()
  const { state, location } = useNavigation()

  const isRefreshingData = Boolean(location && pathname.includes(location?.pathname))
  const isChildRoute = Boolean(location && location?.pathname.includes(pathname))
  const isAccountPage = location?.pathname.includes('account')

  const isLoading = state === 'loading' && !isRefreshingData && !isChildRoute && !isAccountPage

  return (
    <div className="flex flex-col w-full">
      <AuthenticatedNavBar />

      {hasAccess && data ? <Outlet /> : <RequestAccessPage />}
      <AcceptTermsAndConditionsDialog />

      {isLoading ? (
        <div className="fixed top-[10%] left-1/2 -translate-x-1/2">
          <div className="bg-white shadow-lg rounded-half p-xl flex flex-col items-center space-y-md">
            <div className="loadingSpinner text-lg" />
          </div>
        </div>
      ) : null}
    </div>
  )
}
