import React, { FunctionComponent, useState } from 'react'
import { Code } from '../../../shared/Code'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  examples: Record<string, OpenAPIV3_1.ExampleObject>
}

export const ApiExamples: FunctionComponent<Props> = ({ examples }) => {
  const [selectedExample, setSelectedExample] = useState(0)

  const exampleTitles = Object.keys(examples)

  const example = examples[exampleTitles[selectedExample]]

  return exampleTitles.length > 0 ? (
    <div className="bg-grey-light rounded-half">
      <div className="p-sm">
        <label htmlFor="example" className="label">
          Examples
        </label>
        <select
          id="example"
          defaultValue={exampleTitles[0]}
          onChange={({ target }) => setSelectedExample(parseInt(target.value))}
        >
          {exampleTitles.map((title, i) => (
            <option key={`ex-title-${title}`} value={i}>
              {title}
            </option>
          ))}
        </select>
      </div>

      <Code code={example?.value || (example as string)} />
    </div>
  ) : null
}
