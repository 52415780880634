import React, { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { ArrowRightIcon } from '../../../icons'

type Props = {
  isActive: boolean
  path: string
  label: string
  subLabel?: string
}

export const ListItemLink: FunctionComponent<Props> = ({ isActive, path, label, subLabel }) => {
  return (
    <NavLink to={path} className={`relative group block p-md rounded-half w-full ${isActive ? 'bg-grey-light' : ''}`}>
      <span className="flex items-center justify-between">
        <span className="flex flex-col space-y-xs">
          <span className="block max-w-[17.5rem] truncateText">{label}</span>
          {subLabel && <small>{subLabel}</small>}
        </span>

        <span
          className={`transition ${isActive ? 'opacity-100' : 'opacity-0'} group-hover:opacity-100`}
          aria-hidden="true"
        >
          <ArrowRightIcon width="1rem" height="1rem" />
        </span>
      </span>
    </NavLink>
  )
}
