import React, { FunctionComponent } from 'react'
import { MarkdownContent } from '../../../shared/MarkdownContent'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  info: OpenAPIV3_1.InfoObject
  openapi: string
}

export const ApiInfo: FunctionComponent<Props> = ({ info, openapi }) => {
  if (!info) {
    return null
  }

  const { title, version, description } = info

  const isBeta = parseInt(version.toString()[0]) === 0

  return (
    <>
      <div className="relative inline-block">
        {isBeta && <span className="absolute -top-sm right-0 md:-right-4 tag tag-orange">BETA</span>}

        <h1 className="text-xl" id={title}>
          {title}
        </h1>
      </div>

      {isBeta && (
        <div className="mt-sm mb-md bg-grey-light p-md rounded-half block">
          This API is currently experimental and may undergo changes before becoming stable.
        </div>
      )}

      <div className="grid grid-cols-12 gap-y-sm mt-md">
        <div className="col-span-4 md:col-span-3 font-bold">OpenAPI</div>
        <div className="col-span-1">{openapi}</div>
        <div className="col-span-4 md:col-span-3 font-bold row-start-2">API</div>
        <div className="col-span-1 row-start-2">{version}</div>
      </div>

      {description !== undefined && (
        <div className="prose mt-md">
          <MarkdownContent markdown={description} />
        </div>
      )}
    </>
  )
}
