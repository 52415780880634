import React, { FunctionComponent } from 'react'
import { ArrowRightIcon } from '../icons'
import { Link, NavLink, useLocation } from 'react-router-dom'

export type MenuItem = {
  id: string
  label: string
  operationName?: string
  path: string
  children: MenuItem[]
}

type Props = {
  sitePrefix?: string
  menuTitle?: string
  menuItems: MenuItem[]
}

export const VerticalMenu: FunctionComponent<Props> = ({ menuTitle, sitePrefix, menuItems }) => {
  const location = useLocation()

  return (
    <>
      {menuTitle && (
        <div className="ml-md flex items-center space-x-sm">
          {sitePrefix && (
            <>
              <span className="text-sm font-bold">{sitePrefix}</span> <ArrowRightIcon width="0.7rem" height="0.7rem" />
            </>
          )}
          <span className="font-bold text-sm">{menuTitle}</span>
        </div>
      )}

      <div className="flex flex-col space-y-lg mt-md">
        {menuItems.map(item => {
          const hasChildren = item.children.length > 0
          const id = `menu-${item.label}`
          const parentIsActive =
            item.path === location.pathname + location.hash || `/${item.path}` === location.pathname + location.hash

          return (
            <ul key={id} className="pl-sm">
              <li>
                <Link
                  to={item.path}
                  className={`block px-sm py-sm rounded-half font-bold ${parentIsActive ? 'bg-fog' : ''}`}
                >
                  {item.label}
                </Link>

                {hasChildren && (
                  <ul className="pl-sm">
                    {item.children.map((child: any) => {
                      const childIsActive =
                        child.path === location.pathname + location.hash ||
                        `/${child.path}` === location.pathname + location.hash
                      const hasOperationName = !!child.operationName

                      return (
                        <li
                          key={`li-${child.id}`}
                          className={`border-l-2 ${childIsActive ? 'border-blue-dark' : 'border-fog'}`}
                        >
                          <NavLink
                            to={`${child.path}`}
                            className={`flex items-start justify-between space-x-sm px-sm py-sm rounded-r-half ${
                              childIsActive ? 'bg-grey-light' : ''
                            }`}
                          >
                            {hasOperationName ? (
                              <>
                                <span
                                  className={`w-[3rem] pt-[0.16rem] uppercase text-sm font-bold tracking-wide operation ${child.operationName}`}
                                >
                                  {child.operationName}
                                </span>
                                <span className="flex-1">{child.label}</span>
                              </>
                            ) : (
                              <span>{child.label}</span>
                            )}
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            </ul>
          )
        })}
      </div>
    </>
  )
}
