import React, { FunctionComponent, useState } from 'react'
import { RequestStatus, WebhookEventHistoryType } from '../../../../../types'
import { retryWebhookDelivery } from '../../../../../services/account-service'
import { logError } from '@tomra/datadog-browser-logging'

export const RetryWebhookDelivery: FunctionComponent<{
  event: WebhookEventHistoryType
  environment: string
  onComplete: () => void
}> = ({ event, environment, onComplete }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle')
  const [error, setError] = useState<string>('')

  const retry = () => {
    setError('')
    setRequestStatus('pending')

    retryWebhookDelivery(event.webhookId, event.eventId, environment)
      .then(onComplete)
      .catch(error => {
        if (error.status === 404) {
          setError('Retry failed. Webhook event not found.')
        } else {
          setError('Retry failed')
          logError(new Error('Unable to retry webhook delivery'), error)
        }

        setRequestStatus('idle')

        setTimeout(() => {
          setError('')
        }, 8000)
      })
  }

  if (error) {
    return <div className="font-bold text-red border-b border-transparent">{error}</div>
  }

  if (requestStatus === 'pending') {
    return <div className="font-bold border-b border-transparent">Retrying...</div>
  }

  return (
    <>
      <button
        onClick={retry}
        className="text-left text-sm transition active:scale-[0.98] font-bold border-b border-blue-dark"
      >
        Retry
      </button>
    </>
  )
}
