import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const ForbiddenIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <circle cx="12" cy="12" r="11.25" />
    <rect x="5.25" y="9.75" width="13.5" height="4.5" rx="1" ry="1" />
  </svg>
)
