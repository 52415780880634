import React, { FunctionComponent, ReactNode } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '../icons'
import { useSearchParams } from 'react-router-dom'

type Props = {
  children: ReactNode
  hasMorePages: boolean
  showPaginationLinks: boolean
}

export const PaginatedContent: FunctionComponent<Props> = ({ children, hasMorePages, showPaginationLinks }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentPage = searchParams.get('page') || '0'
  const nextPage = (parseInt(currentPage) + 1).toString()
  const prevPage = (parseInt(currentPage) - 1).toString()

  const existingParams = Object.fromEntries(searchParams)

  const goToNextPage = () => {
    setSearchParams({ ...existingParams, page: nextPage })
  }

  const goToPrevPage = () => {
    setSearchParams({ ...existingParams, page: prevPage })
  }

  return (
    <>
      {children}

      {showPaginationLinks && (
        <div className="flex justify-center mt-md">
          {currentPage !== '0' && (
            <button onClick={goToPrevPage}>
              <span className="flex items-center space-x-xs mr-sm">
                <ArrowLeftIcon width="1.1rem" height="1.1rem" color="var(--colors-blue-dark)" title="" />
                <span className="link">Previous page</span>
              </span>
            </button>
          )}

          {hasMorePages && (
            <button onClick={goToNextPage}>
              <span className="flex items-center space-x-xs ml-sm">
                <span className="link">Next page</span>
                <ArrowRightIcon width="1.1rem" height="1.1rem" color="var(--colors-blue-dark)" title="" />
              </span>
            </button>
          )}
        </div>
      )}
    </>
  )
}
