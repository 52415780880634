import React, { FunctionComponent, useState } from 'react'
import { CopyIcon } from '../../../icons'
import { ampli } from '../../../../ampli'

type Props = {
  label: string
  value: string | undefined
}

const noOp = () => {}

export const CopyTextField: FunctionComponent<Props> = ({ label, value }) => {
  const [copied, setCopied] = useState<boolean>(false)

  ampli.copyText({ label })

  const copyToClipboard = () => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 3000)
        })
        .catch(noOp)
    }
  }

  return (
    <button type="button" aria-label={`Copy ${label}`} onClick={copyToClipboard} className="w-full">
      <span className="relative flex items-center w-full text-left bg-grey-light group rounded-half py-xs px-sm">
        <pre aria-label={label} className="flex-1 p-sm rounded-half text-colors-storm truncate">
          {value}
        </pre>

        {copied ? (
          <span className="absolute right-3 text-white text-sm bg-black-transparent p-sm rounded-half font-bold">
            Copied!
          </span>
        ) : (
          <span className="absolute right-3 text-white text-sm bg-black-transparent p-sm rounded-half font-bold opacity-0 group-hover:opacity-100">
            Click to copy
          </span>
        )}

        <CopyIcon width="1.5rem" height="1.5rem" />
      </span>
    </button>
  )
}
