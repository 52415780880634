import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { ApiExamples } from './ApiExamples'
import { ApiSchemaRecursive } from './ApiSchemaRecursive'
import { motion } from 'framer-motion'

type Props = {
  requestBody: OpenAPIV3_1.RequestBodyObject
}

export const ApiRequestBody: FunctionComponent<Props> = ({ requestBody }) => {
  if (!requestBody) {
    return null
  }

  const { description, required, content } = requestBody
  const mediaType = Object.keys(content)?.[0]
  const schema = content[mediaType].schema as OpenAPIV3_1.SchemaObject
  const examples = content[mediaType].examples as Record<string, OpenAPIV3_1.ExampleObject>

  return (
    <motion.section>
      <div className="flex items-center space-x-sm mb-md">
        <h2 className="m-0 text-lg">Request body</h2>
        <pre className="p-xs text-sm font-bold bg-grey-light rounded-half inline">{mediaType}</pre>
        {required && <span className="pl-xs text-red text-sm font-bold">required</span>}
      </div>

      {description && <p>{description}</p>}

      <div className="grid grid-cols-2 gap-lg">
        <div className={examples ? 'col-span-1' : 'col-span-2'}>
          <ApiSchemaRecursive level={0} schema={schema} />
        </div>

        <div className="col-span-1">{examples ? <ApiExamples examples={examples} /> : null}</div>
      </div>
    </motion.section>
  )
}
