import React from 'react'
import { Form, useActionData, useNavigate, useNavigation, useParams, useRouteLoaderData } from 'react-router-dom'
import { Account, ActionData } from '../../../../types'

export const CreateOAuthClientForm = () => {
  const { environment } = useParams()
  const navigate = useNavigate()
  const { state, formData } = useNavigation()
  const data = useActionData() as ActionData
  const account = useRouteLoaderData('account') as Account
  const apiKeyPropertyName = environment === 'sandbox' ? 'sandboxApiKey' : 'productionApiKey'
  const hasApiKeyForEnvironment = account && account[apiKeyPropertyName]

  const isWorking = formData && (state === 'submitting' || state === 'loading')

  return (
    <Form method="post" className="space-y-md">
      <h2 className="text-lg">Create {environment} OAuth Client</h2>

      {!hasApiKeyForEnvironment ? (
        <div className="alert alert-warning">
          You need a {environment} API key to create clients for the {environment} environment.
          <br />
          Please contact support.
        </div>
      ) : null}

      <div>
        <label htmlFor="clientName" className="label mt-md">
          Name
        </label>
        <input id="clientName" type="text" name="clientName" required autoComplete="off" />
      </div>

      {data?.error && !isWorking ? <div className="text-red font-bold">{data?.error}</div> : null}

      <div className="flex justify-end space-x-md">
        <button type="button" className="btn" onClick={() => navigate(`/account/${environment}/oauth-clients`)}>
          Cancel
        </button>

        <button type="submit" className="btn btn-primary-dark flex items-center justify-center">
          Create
        </button>
      </div>
    </Form>
  )
}
