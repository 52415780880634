import React, { Component, ReactNode } from 'react'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  errorMsg?: string
  children: ReactNode
}

type State = {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line no-console
    console.log(error)
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    logError(new Error('Error caught in ErrorBoundary: '), error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="font-bold text-red">
          {this.props.errorMsg || 'Something went wrong. We apologize for the inconvenience.'}
        </div>
      )
    }

    return this.props.children
  }
}
