import React from 'react'
import { Notice } from '../shared/Notice'
import { ErrorIcon } from '../icons'

export const PageNotFoundPage = () => {
  return (
    <main className="h-full w-full mt-6 mx-auto" style={{ maxWidth: 'var(--maxWidth)' }}>
      <Notice title="Uh oh..." icon={<ErrorIcon size="4rem" color="var(--colors-orange)" />}>
        <p>We could not find the page you we&apos;re looking for.</p>
      </Notice>
    </main>
  )
}
