import React, { FunctionComponent, useState } from 'react'
import { JSONTreeView } from '../pages/api-doc/common/JSONTreeView'
import { CopyIcon } from '../icons'

const noOp = () => {}

type Props = {
  code: string
}

export const Code: FunctionComponent<Props> = ({ code }) => {
  const [expandAll, setExpandAll] = useState(false)
  const [copied, setCopied] = useState<boolean>(false)

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(JSON.stringify(code))
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
      })
      .catch(noOp)
  }

  return (
    <div className="relative p-sm">
      {copied && (
        <div className="absolute bg-black-transparent font-bold rounded-half py-xs px-sm top-[4rem] right-sm text-white">
          Copied!
        </div>
      )}
      <div className="flex justify-end space-x-sm">
        <button onClick={copyToClipboard}>
          <span className="border-2 border-dust px-sm py-xs rounded-half flex items-center space-x-xs transition active:scale-[0.98]">
            <CopyIcon width="1.5rem" height="1.5rem" />
            <span>Copy</span>
          </span>
        </button>
        <button
          className={`border-2 border-dust px-sm py-xs rounded-half transition active:scale-[0.98]`}
          onClick={() => setExpandAll(true)}
        >
          Expand all
        </button>
        <button
          className={`border-2 border-dust px-sm py-xs rounded-half transition active:scale-[0.98]`}
          onClick={() => setExpandAll(false)}
        >
          Collapse all
        </button>
      </div>

      <JSONTreeView level={0} data={code} expandAll={expandAll} />
    </div>
  )
}
