import React from 'react'

export const WebhookEventSkeleton = () => (
  <div className="py-md w-full animate-pulse">
    <span>
      <span className="flex items-center space-x-md">
        <span className="w-2 h-2 rounded bg-fog" />

        <span className="flex flex-col space-y-xs items-start justify-start flex-1">
          <span className="w-12 h-[1.5rem] rounded-half bg-fog" />
          <span className="flex flex-col space-y-xs">
            <span className="w-10 h-[1.5rem] rounded-half bg-fog" />
          </span>
        </span>

        <span className="flex flex-col items-end justify-start space-y-xs">
          <span className="w-17 h-[1.5rem] rounded-half bg-fog" />
          <span className="w-4 h-[1.5rem] rounded-half bg-fog" />
        </span>
      </span>
    </span>
  </div>
)
