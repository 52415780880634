import React from 'react'
import { Link, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { OAuthClient } from '../../../../types'
import { ListItemLink } from '../common/ListItemLink'

export const OAuthClientList = () => {
  const oAuthClients = useLoaderData() as OAuthClient[]
  const { pathname } = useLocation()
  const { environment } = useParams()

  const hasReachedMaximumClientNumber = oAuthClients.length === 3

  return (
    <div className="card p-lg">
      <div className="flex justify-between mb-md">
        <h2 className="text-lg">OAuth Clients</h2>

        {!hasReachedMaximumClientNumber && (
          <Link className="btn" to="create">
            Add
          </Link>
        )}
      </div>

      {hasReachedMaximumClientNumber && (
        <small className="block mb-md">You&apos;ve reached the maximum number of {environment} clients.</small>
      )}

      <ul>
        {oAuthClients.map(client => (
          <li key={client.id}>
            <ListItemLink isActive={pathname.includes(client.id)} path={client.id} label={client.name} />
          </li>
        ))}
      </ul>
    </div>
  )
}
