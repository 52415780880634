import React, { useId } from 'react'
import { useFetcher, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom'
import { Dialog } from '../../../shared/Dialog'
import { OAuthClient } from '../../../../types'

export const DeleteOAuthClientConfirmation = () => {
  const { environment, clientId } = useParams()
  const navigate = useNavigate()
  const fetcher = useFetcher()

  const a11yTitleId = useId()
  const a11yDescriptionId = useId()

  const clients = useRouteLoaderData('oauthClients') as OAuthClient[]
  const client = clients.find(c => c.id === clientId) as OAuthClient

  const isWorking = fetcher.state === 'submitting' || fetcher.state === 'loading'

  return (
    <Dialog labeledBy={a11yTitleId} describedBy={a11yDescriptionId}>
      <div className="card p-xl prose dialog-content">
        {isWorking ? (
          <div className="centerFlex">
            <div className="loadingSpinner" />
          </div>
        ) : (
          <fetcher.Form method="delete" action={`/account/${environment}/oauth-clients/${client.id}/delete`}>
            <h1 id={a11yTitleId}>Delete &quot;{client.name}&quot;?</h1>
            <p id={a11yDescriptionId}>This action cannot be undone.</p>

            <input type="hidden" className="hidden" name="clientId" value={client.id} />

            <div className="flex justify-end space-x-md">
              <button type="button" className="btn" onClick={() => navigate(-1)}>
                No
              </button>
              <button type="submit" className="btn btn-primary-danger">
                Delete
              </button>
            </div>
          </fetcher.Form>
        )}
      </div>
    </Dialog>
  )
}
