import { Link, useLoaderData, useLocation } from 'react-router-dom'
import React from 'react'
import { Webhook } from '../../../../types'
import { ListItemLink } from '../common/ListItemLink'

export const WebhookList = () => {
  const { webhooks } = useLoaderData() as { webhooks: Webhook[] }
  const { pathname } = useLocation()

  return (
    <div className="card p-lg">
      <div className="flex justify-between mb-md">
        <h2 className="text-lg">Webhooks</h2>
        <Link className="btn" to="create">
          Add
        </Link>
      </div>

      <ul>
        {webhooks.map(webhook => {
          const { id, url, active } = webhook
          const isActive = pathname.includes(id as string)

          return (
            <li key={id} className="flex items-center space-x-md">
              <ListItemLink
                isActive={isActive}
                path={id as string}
                label={url}
                subLabel={active ? 'Active' : 'Inactive'}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
