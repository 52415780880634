import { motion } from 'framer-motion'
import React, { FunctionComponent, useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children?: React.ReactNode
  onClose?: () => void
  shouldCloseOnOverlayClick?: boolean
  labeledBy: string
  describedBy: string
}

export const Dialog: FunctionComponent<Props> = ({
  children,
  onClose,
  shouldCloseOnOverlayClick = true,
  labeledBy,
  describedBy
}) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (onClose && event.key === 'Escape') {
        event.stopPropagation()
        onClose()
      }
    }

    const root = document.getElementById('root')
    if (root) {
      root.classList.add('modal-open')
    }

    window.addEventListener('keydown', handleEscapeKey)

    return () => {
      window.removeEventListener('keydown', handleEscapeKey)

      if (root) {
        root.classList.remove('modal-open')
      }
    }
  }, [onClose])

  return createPortal(
    <motion.div className="dialog-overlay" onClick={shouldCloseOnOverlayClick ? onClose : () => {}}>
      <motion.div
        role="alertdialog"
        aria-modal="true"
        aria-labelledby={labeledBy}
        aria-describedby={describedBy}
        className="lg:dialog-content"
        onClick={evt => evt.stopPropagation()}
        initial={{ opacity: 0, y: '-200%' }}
        animate={{ opacity: 1, y: '0' }}
        exit={{ opacity: 0, y: '-200%' }}
      >
        {children}
      </motion.div>
    </motion.div>,
    document.getElementById('root') as HTMLDivElement
  )
}
