import React from 'react'
import { Link, NavLink, Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { Account } from '../../../types'
import { ViewTermsAndConditions } from './ViewTermsAndConditions'
import { ForbiddenIcon } from '../../icons'
import { Notice } from '../../shared/Notice'
import { motion } from 'framer-motion'

export const AccountPage = () => {
  const account = useLoaderData() as Account
  const { environment } = useParams()

  const { pathname } = useLocation()
  const currentPath = pathname.split(`/`)?.[3]

  const hasApiLicenses = account.licenses.length > 0

  return hasApiLicenses ? (
    <>
      <div className="lg:mt-[var(--navigationBarHeight)]">
        <div className="mx-auto max-w-screen-2xl px-md pt-lg">
          <div className="grid grid-cols-12 gap-md items-end">
            <div className="col-span-8">
              <h1>Account</h1>
              <div className="text-xl">{account.accountName}</div>
              <ViewTermsAndConditions />
            </div>

            <div className="tabs col-span-12 md:col-span-4 bg-white">
              <Link to={`/account/sandbox${currentPath ? `/${currentPath}` : ''}`} className="relative tab">
                <span>
                  <span className="relative z-[1]">Sandbox</span>
                  {pathname.includes('sandbox') ? (
                    <motion.span
                      layoutId="environment-underline"
                      className="absolute top-0 h-[3.4rem] left-[2px] right-0 rounded-l bg-[rgb(143_223_236)] z-0"
                    />
                  ) : null}
                </span>
              </Link>
              <Link to={`/account/production${currentPath ? `/${currentPath}` : ''}`} className="relative tab">
                <span>
                  <span className="relative z-[1]">Production</span>
                  {pathname.includes('production') ? (
                    <motion.span
                      layoutId="environment-underline"
                      className="absolute top-0 rounded-r h-[3.4rem] left-[2px] right-0 bg-[rgb(143_223_236)] z-0"
                    />
                  ) : null}
                </span>
              </Link>
            </div>
          </div>

          <div className="flex items-end space-x-lg mt-lg">
            <NavLink to={`/account/${environment || 'sandbox'}`} end className="my-md relative">
              <span>
                <span>Overview</span>
                {pathname.endsWith('sandbox') || pathname.endsWith('production') ? (
                  <motion.span layoutId="underline" className="absolute bottom-0 left-0 right-0 h-[2px] bg-blue-dark" />
                ) : null}
              </span>
            </NavLink>
            <NavLink to={`/account/${environment || 'sandbox'}/oauth-clients`} className="my-md relative">
              <span>
                <span>OAuth Clients</span>
                {pathname.includes('oauth-clients') && (
                  <motion.span layoutId="underline" className="absolute bottom-0 left-0 right-0 h-[2px] bg-blue-dark" />
                )}
              </span>
            </NavLink>
            <NavLink to={`/account/${environment || 'sandbox'}/webhooks`} className="my-md relative">
              <span>
                <span>Webhooks</span>
                {pathname.includes('webhooks') && (
                  <motion.span layoutId="underline" className="absolute bottom-0 left-0 right-0 h-[2px] bg-blue-dark" />
                )}
              </span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="max-w-screen mx-md md:mx-auto md:max-w-screen-2xl w-full">
        <Outlet />
      </div>
    </>
  ) : (
    <div className="max-w-screen mx-md md:mx-auto md:max-w-screen-2xl lg:mt-[var(--navigationBarHeight)] prose py-lg">
      <Notice icon={<ForbiddenIcon size="4rem" color="var(--colors-orange)" />} title="Sorry">
        <p className="text-center">
          You don&apos;t have access to the account page.
          <br />
          Please contact your local sales office to request access.
        </p>

        <a href="https://www.tomra.com/en/contact-us" target="_blank" rel="noreferrer" className="font-bold">
          Find your local sales office
        </a>
      </Notice>
    </div>
  )
}
