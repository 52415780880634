import React, { FunctionComponent } from 'react'

type Props = {
  isOpen: boolean
}

export const AnimatedHamburgerIcon: FunctionComponent<Props> = ({ isOpen }) => {
  const barClasses = `block absolute h-[0.4rem] w-full left-0 bg-white rounded-[0.5625rem] opacity-1 transform rotate-0 transition-transform`

  return (
    <div className={`relative w-[2.5rem] h-[2rem] transform rotate-0 transition-transform ${isOpen ? 'open' : ''}`}>
      <span className={`top-0 ${barClasses} ${isOpen ? 'top-[0.4rem] opacity-0 left-[50%]' : 'opacity-100'}`} />
      <span className={`top-[0.8rem] ${barClasses} ${isOpen ? 'rotate-45' : ''}`} />
      <span className={`top-[0.8rem] ${barClasses} ${isOpen ? '-rotate-45' : ''}`} />
      <span className={`top-[1.6rem] ${barClasses} ${isOpen ? 'top-[0.4rem] opacity-0 left-[50%]' : 'opacity-100'}`} />
    </div>
  )
}
