import React, { FunctionComponent, useState } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { ApiExamples } from './ApiExamples'
import { AnimatePresence, motion } from 'framer-motion'
import { MinusIcon, PlusIcon } from '../../../icons'
import { ApiSchemaRecursive } from './ApiSchemaRecursive'
import { expandAnimation } from '../../../shared/animationVariants'

type Props = {
  title: string
  response: OpenAPIV3_1.ResponseObject
}

export const ApiResponse: FunctionComponent<Props> = ({ title, response }) => {
  const [isOpen, setIsOpen] = useState(false)
  const isSuccess = parseInt(title, 10) < 400

  const bgColorLight = isSuccess ? 'bg-[rgba(0,150,143,0.1)]' : 'bg-[rgba(138,18,56,0.1)]'
  const bgColorDark = isSuccess ? 'bg-[rgba(0,150,143,0.4)]' : 'bg-[rgba(138,18,56,0.1)]'
  const borderColorLight = isSuccess ? 'border-[rgba(0,150,143,0.1)]' : 'border-[rgba(138,18,56,0.1)]'

  const { content, description } = response

  if (!description) {
    return null
  }

  const mediaType = content ? Object.keys(content)?.[0] : null
  const schema = content && mediaType ? (content[mediaType].schema as OpenAPIV3_1.SchemaObject) : null
  const examples =
    content && mediaType ? (content[mediaType].examples as Record<string, OpenAPIV3_1.ExampleObject>) : null

  const ButtonOrDivContainer: FunctionComponent<{ children: React.ReactNode }> = props => {
    return schema ? (
      <button
        className={`${bgColorLight} ${isOpen ? 'rounded-t-half' : 'rounded-half'} w-full p-sm text-left`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center justify-between space-x-sm">
          {props.children}

          <span aria-hidden="true">
            {isOpen ? <MinusIcon width="1.5rem" height="1.5rem" /> : <PlusIcon width="1.5rem" height="1.5rem" />}
          </span>
        </span>
      </button>
    ) : (
      <div className={`${bgColorLight} rounded-half w-full p-sm`}>{props.children}</div>
    )
  }

  return (
    <li
      aria-label={`${title} response`}
      className={`border-2 rounded-half ${isOpen ? `${borderColorLight}` : 'border-transparent'}`}
    >
      <ButtonOrDivContainer>
        <span className="flex items-center space-x-sm">
          <span className={`inline-block font-bold p-sm rounded-half ${bgColorDark}`}>{title}</span>
          <h4 className="font-bold mb-0">{description}</h4>
          <span className="p-xs text-sm font-bold bg-grey-light rounded-half inline">{mediaType}</span>
        </span>
      </ButtonOrDivContainer>

      <AnimatePresence key={`response-${title}`}>
        {isOpen && schema ? (
          <motion.div className="p-sm grid grid-cols-2 gap-sm" {...expandAnimation}>
            <ul className={examples && Object.keys(examples).length > 0 ? 'col-span-1' : 'col-span-2'}>
              <ApiSchemaRecursive name={schema.title} level={1} schema={schema} />
            </ul>

            {examples && (
              <motion.div className="col-span-1">
                <ApiExamples examples={examples} />
              </motion.div>
            )}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </li>
  )
}
