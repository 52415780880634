import React, { useEffect, useState } from 'react'
import { Dialog } from '../shared/Dialog'
import { acceptTermsOfService, acceptedTermsOfService } from './termsOfServiceHelper'
import { TermsAndConditions } from './TermsAndConditions'
import { AnimatePresence } from 'framer-motion'
import { hasDeveloperPortalLicense } from '../../lib/authentication'

const noOp = () => {}

export const AcceptTermsAndConditionsDialog = () => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean | undefined>(undefined)
  const [hasCheckedCheckbox, setHasCheckedCheckbox] = useState(false)

  useEffect(() => {
    acceptedTermsOfService().then(hasAccepted => setHasAcceptedTerms(!!hasAccepted))
  }, [])

  const acceptTerms = () => acceptTermsOfService().then(() => setHasAcceptedTerms(true))

  return (
    <AnimatePresence>
      {hasAcceptedTerms !== undefined && !hasAcceptedTerms && hasDeveloperPortalLicense() ? (
        <Dialog labeledBy="termsAndConditionsTitle" describedBy="termsAndConditionsDescription">
          <div className="h-[100dvh] flex flex-col justify-between lg:max-h-[70dvh]">
            <div className="flex-1 relative bg-white overflow-scroll min-w-screen lg:min-w-screen-md lg:rounded-t-half">
              <TermsAndConditions />
            </div>
            <div className="bg-grey-light w-full pl-md pr-md py-md lg:rounded-b-half lg:flex lg:items-center lg:justify-between lg:pl-xl">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="checkbox self-start mr-sm"
                  onChange={() => setHasCheckedCheckbox(!hasCheckedCheckbox)}
                  checked={hasCheckedCheckbox}
                />
                Yes, I accept these terms and conditions
              </label>

              <button
                type="button"
                className="btn btn-primary-dark w-full mt-md lg:w-10 lg:mt-0"
                aria-disabled={!hasCheckedCheckbox}
                onClick={hasCheckedCheckbox ? acceptTerms : noOp}
              >
                Accept
              </button>
            </div>
          </div>
        </Dialog>
      ) : null}
    </AnimatePresence>
  )
}
