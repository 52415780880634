import React, { FunctionComponent, useRef } from 'react'
import { ActionData, Webhook, WebhooksLoaderData } from '../../../../types'
import { Form, useActionData, useNavigation, useRouteLoaderData } from 'react-router-dom'
import { WebhookSecretDialog } from './WebhookSecretDialog'

type Props = {
  formMethod: 'patch' | 'post'
  formAction: string
  webhook: Webhook
  onCancel: () => void
}

export const WebhookForm: FunctionComponent<Props> = ({ webhook, formAction, formMethod, onCancel }) => {
  const { state, formData } = useNavigation()
  const { webhookEventTypes } = useRouteLoaderData('webhooks') as WebhooksLoaderData
  const data = useActionData() as ActionData
  const webhookEventCheckboxRef = useRef<HTMLInputElement[]>([])

  const isWorking = formData && (state === 'submitting' || state === 'loading')

  const webhookCreated = Boolean(data?.success?.secret)

  const toggleAllEventTypes = (target: HTMLInputElement) => {
    if (webhookEventCheckboxRef.current) {
      webhookEventCheckboxRef.current.map(checkbox => (checkbox.checked = target.checked))
    }
  }

  return (
    <React.Fragment>
      <Form method={formMethod} action={formAction} className="flex flex-col space-y-lg">
        <div>
          <label htmlFor="url" className="label">
            URL
          </label>
          <input id="url" type="url" name="url" required defaultValue={webhook?.url || 'https://'} />
        </div>

        <div className="flex items-center justify-between space-x-md">
          <div className="flex-1">
            <label htmlFor="active" className="label">
              Active
            </label>
            <small>The webhook must be active in order to receive events</small>
          </div>
          <input
            id="active"
            type="checkbox"
            className="switch"
            name="active"
            defaultChecked={webhook ? Boolean(webhook?.active) : true}
          />
        </div>

        <fieldset className="flex flex-col space-y-sm">
          <legend className="label mb-sm">Select events to listen to</legend>

          <label className="label select-none">
            <input
              type="checkbox"
              className="checkbox mr-sm"
              defaultChecked={webhook?.events.length === webhookEventTypes.length}
              onChange={event => toggleAllEventTypes(event.target)}
            />
            All events
          </label>

          {webhookEventTypes.map((webhookEventType, i) => (
            <label key={webhookEventType} className="label select-none ml-md">
              <input
                ref={el => (el ? (webhookEventCheckboxRef.current[i] = el) : null)}
                id={webhookEventType}
                type="checkbox"
                className="mr-sm"
                name="events"
                value={webhookEventType}
                defaultChecked={Boolean(webhook?.events.includes(webhookEventType))}
              />
              {webhookEventType}
            </label>
          ))}
        </fieldset>

        {data?.error && !isWorking ? <div className="text-red font-bold">{data?.error}</div> : null}

        <div className="mt-lg flex justify-end space-x-md">
          <button type="button" className="btn" onClick={onCancel}>
            Cancel
          </button>

          <button type="submit" className="btn btn-primary-dark flex items-center justify-center">
            {isWorking ? <div className="loadingSpinner" /> : 'Save'}
          </button>
        </div>
      </Form>

      {webhookCreated ? <WebhookSecretDialog secret={data.success.secret} onClose={onCancel} /> : null}
    </React.Fragment>
  )
}
