import { motion } from 'framer-motion'
import React from 'react'
import { Link, NavLink, Outlet, useLoaderData, useLocation } from 'react-router-dom'
import { Webhook } from '../../../../types'
import { WebhookStateIndicator } from './WebhookStateIndicator'

export const WebhookDetails = () => {
  const webhook = useLoaderData() as Webhook
  const { url, events, active } = webhook
  const { pathname } = useLocation()

  return (
    <>
      <div className="card p-lg">
        <pre className="flex items-center justify-between space-x-md text-left p-sm rounded-half bg-grey-light flex-1 truncateText">
          <WebhookStateIndicator isActive={active} />
          <span className="flex-1">{url}</span>
        </pre>

        <div className="mt-md flex justify-between items-start ml-sm">
          <div className="flex flex-col">
            {events.map(event => (
              <pre key={`event-${event}`} className="text-sm">
                {event}
              </pre>
            ))}
          </div>

          <div className="flex justify-end space-x-md">
            <Link to="edit" className="btn">
              Edit
            </Link>
            <Link to="delete" className="btn text-red border-red">
              Delete
            </Link>
          </div>
        </div>
      </div>

      <div className="flex space-x-md mt-md ml-md">
        <NavLink to="" end className="my-md relative">
          <span>
            <span>Events</span>
            {!pathname.endsWith('/notifications') && (
              <motion.span
                layoutId="events-notifications-underline"
                className="absolute bottom-0 left-0 right-0 h-[2px] bg-blue-dark"
              />
            )}
          </span>
        </NavLink>
        <NavLink to="notifications" end className="my-md relative">
          <span>
            <span>Notifications</span>
            {pathname.endsWith('/notifications') && (
              <motion.span
                layoutId="events-notifications-underline"
                className="absolute bottom-0 left-0 right-0 h-[2px] bg-blue-dark"
              />
            )}
          </span>
        </NavLink>
      </div>

      <Outlet />
    </>
  )
}
