import React, { useEffect, useRef } from 'react'
import { useLoaderData, useLocation, useParams } from 'react-router-dom'
import { OpenAPIV3_1 } from 'openapi-types'
import { getTags, mapPathsToTags } from './spec-helpers'
import yaml from 'js-yaml'
import { parseOpenApiSpec } from '../../../lib/specParser'
import { ApiDocMenu } from './common/ApiDocMenu'
import { ApiInfo } from './common/ApiInfo'
import { ApiAuthentication } from './common/ApiAuthentication'
import { ApiTag } from './common/ApiTag'
import { ApiPaths } from './common/ApiPaths'
import { ampli } from '../../../ampli'

export const ApiDocPage = () => {
  const { api: name } = useParams() as { api: string }

  const spec = useLoaderData() as string
  const jsonRaw = yaml.load(spec) as OpenAPIV3_1.Document

  const json = parseOpenApiSpec(jsonRaw)

  const paths = json.paths as OpenAPIV3_1.PathsObject
  const pathTags = getTags(paths)
  const pathsByTags = mapPathsToTags(paths)

  const webhooks = json.webhooks as OpenAPIV3_1.PathsObject
  const webhookTags = getTags(webhooks)
  const webhookByTags = mapPathsToTags(webhooks)

  const components = json.components as OpenAPIV3_1.ComponentsObject

  const location = useLocation()
  const scrollRef = useRef(null)

  useEffect(() => {
    if (location.hash) {
      const [, id] = location.hash.split('#')
      const el = document.getElementById(id)

      if (el && el.scrollIntoView) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location, scrollRef])

  const downloadApiSpec = () => {
    ampli.openApiSpecDownloaded({ name })
    const blob = createBlob(spec)
    triggerDownload(blob, name!)
  }

  return (
    <div className="w-full lg:py-lg max-w-screen-2xl mx-auto grid grid-cols-12 gap-x-xl lg:mt-[var(--navigationBarHeight)]">
      <div className="hidden lg:block lg:col-span-3">
        <ApiDocMenu
          pathsByTags={pathsByTags}
          pathTags={pathTags}
          name={name}
          webhookTags={webhookTags}
          webhookByTags={webhookByTags}
        />
      </div>

      <div className="col-span-12 lg:col-span-9">
        <div className="flex flex-col lg:flex-row items-start justify-between">
          <div>
            <ApiInfo info={json.info} openapi={json.openapi} />
          </div>

          <button className="btn w-full mb-md lg:mb-0 lg:w-auto" onClick={downloadApiSpec}>
            Download OpenAPI
          </button>
        </div>

        <ApiAuthentication
          securitySchemes={components.securitySchemes as Record<string, OpenAPIV3_1.SecuritySchemeObject>}
        />

        {pathTags.map(tag => (
          <ApiTag key={`t-${tag}`} tag={tag} pathsByTags={pathsByTags}>
            <ApiPaths tag={tag} pathsByTags={pathsByTags} />
          </ApiTag>
        ))}

        {webhookTags.map(tag => (
          <ApiTag key={`t-${tag}`} tag={tag} pathsByTags={pathsByTags}>
            <ApiPaths tag={tag} pathsByTags={webhookByTags} />
          </ApiTag>
        ))}
      </div>
    </div>
  )
}

const createBlob = (data: string) => new Blob([data], { type: 'application/x-yaml' })

const triggerDownload = (blob: Blob, filename: string) => {
  try {
    const link = document.createElement('a')

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', `Tomra${filename}.yaml`)
      link.style.visibility = 'hidden'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (e) {
    throw new Error(`Error while triggering charity report download`)
  }
}
