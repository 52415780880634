import React from 'react'
import { CopyTextField } from './common/CopyTextField'
import { ENVIRONMENT } from '../../../lib/environment'
import { useParams } from 'react-router-dom'

export const ApiUrls = () => {
  const { environment } = useParams()
  const region = ENVIRONMENT.replace(/(.test|.prod)/g, '')
  const environmentUrl = environment === 'sandbox' ? `https://${region}-sandbox` : `https://${region}`

  return (
    <section id="urlSection" className="card p-lg">
      <h2 className="mb-md text-md capitalize">{environment} URLs</h2>

      <h3 className="ml-sm">API</h3>
      <CopyTextField label={`${environment} API url`} value={`${environmentUrl}.api.developer.tomra.cloud`} />

      <h3 className="ml-sm mt-md">Auth</h3>
      <CopyTextField label={`${environment} auth url`} value={`${environmentUrl}.auth.developer.tomra.cloud`} />
    </section>
  )
}
