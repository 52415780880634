import React, { FunctionComponent, useState } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { motion } from 'framer-motion'
import { ApiSchemaRecursive } from './ApiSchemaRecursive'

type Props = {
  schema: OpenAPIV3_1.SchemaObject
}

export const ApiSchemaSelector: FunctionComponent<Props> = ({ schema }) => {
  const items = (schema.oneOf as OpenAPIV3_1.SchemaObject[]) || (schema.anyOf as OpenAPIV3_1.SchemaObject[])
  const tabs = items?.map((obj: OpenAPIV3_1.SchemaObject) => obj.title)
  const [selectedTab, setSelectedTab] = useState(tabs?.[0])

  if (!tabs) {
    return null
  }

  const selectedSchema = items.reduce((acc, sc) => {
    if (sc.title === selectedTab) {
      return { ...sc, properties: { ...sc.properties, ...schema.properties } }
    }

    return acc
  }, {}) as OpenAPIV3_1.SchemaObject

  return (
    <>
      <div className="flex flex-wrap bg-fog -m-sm p-sm">
        {items.map(tab => {
          const { title, type } = tab

          return (
            <button
              key={`tab-${title}`}
              onClick={() => setSelectedTab(title)}
              className="relative p-sm overflow-hidden border-t-2 border-b-2 border-dust [&:first-child]:border-l-2 [&:first-child]:rounded-l-half [&:last-child]:border-r-2 [&:last-child]:rounded-r-half"
            >
              <span className="flex items-center justify-center space-x-xs relative z-[1]">
                <span>{title}</span>
                <pre
                  className={`p-xs text-sm font-bold rounded-half ${
                    selectedTab === title ? 'bg-grey-light' : 'bg-fog'
                  }`}
                >
                  {type}
                </pre>
              </span>

              {selectedTab === title && (
                <motion.span
                  layoutId="tab-underline"
                  className="absolute top-[0.25rem] left-[0.25rem] rounded-half bg-white w-[calc(100%-0.5rem)] h-[calc(100%-0.5rem)]"
                />
              )}
            </button>
          )
        })}
      </div>

      {selectedSchema && (
        <div className="-m-sm p-sm bg-fog">
          <ApiSchemaRecursive level={0} schema={selectedSchema} />
        </div>
      )}
    </>
  )
}
