import React from 'react'
import { CopyTextField } from './common/CopyTextField'
import { Account } from '../../../types'
import { Link, useParams, useRouteLoaderData } from 'react-router-dom'

export const APIKeys = () => {
  const { environment } = useParams()
  const account = useRouteLoaderData('account') as Account
  const apiKey = environment === 'sandbox' ? account.sandboxApiKey : account.productionApiKey

  return (
    <section id="apiKeySection" className="card p-lg">
      <h2 className="mb-md text-md capitalize">{environment} API Key</h2>

      {apiKey ? (
        <React.Fragment>
          <CopyTextField label={`${environment} API Key`} value={apiKey} />

          <Link to="/#authentication" className="link inline-block mt-md">
            Read about authentication
          </Link>
        </React.Fragment>
      ) : (
        <div>No {environment} API key registered</div>
      )}
    </section>
  )
}
