import React, { FunctionComponent, useId, useState } from 'react'
import { Webhook, WebhookEventHistoryType } from '../../../../../types'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import { ClickToCopySection } from '../../common/ClickToCopySection'
import { AlertIcon, CheckmarkIcon, CopyIcon, RetryIcon, TimeIcon } from '../../../../icons'
import { RetryWebhookDelivery } from './RetryWebhookDelivery'
import { AnimatePresence, motion } from 'framer-motion'
import { expandAnimation } from '../../../../shared/animationVariants'
import { ampli } from '../../../../../ampli'

const webhookStatusMap: { [key: string]: string } = {
  DELIVERED: 'Delivered',
  RETRYING: 'Retrying',
  FAILED: 'Failed',
  USER_INITIATED_RETRY: 'User triggered retry'
}

const webhookStatusDetailMap: { [key: string]: string } = {
  HTTP_TIMEOUT: 'HTTP timeout',
  HTTP_OTHER: 'Other error',
  UNKNOWN_HOST: 'Invalid hostname',
  MAX_RETRIES: 'Max. retries'
}

const getReadableWebhookStatusDetail = (statusDetail: string) => {
  if (webhookStatusDetailMap[statusDetail]) {
    return webhookStatusDetailMap[statusDetail].toLowerCase()
  }

  if (statusDetail.includes('HTTP_CODE_')) {
    const [, httpCode] = statusDetail.split('HTTP_CODE_')
    return `http code ${httpCode}`
  }

  return statusDetail.toLowerCase()
}

type Props = {
  event: WebhookEventHistoryType
}

export const WebhookHistoryEvent: FunctionComponent<Props> = ({ event }) => {
  const { events } = useRouteLoaderData('webhook') as Webhook
  const { eventId, eventType, payload, status, statusDetail, statusUpdatedAt } = event
  const { environment } = useParams() as { environment: string; webhookId: string }

  const [optimisticStatus, setOptimisticStatus] = useState<WebhookEventHistoryType['status'] | 'USER_INITIATED_RETRY'>(
    status
  )
  const [showEventPayload, setShowEventPayload] = useState(false)

  const accordionTitleId = useId()
  const accordionContentId = useId()

  const isDelivered = optimisticStatus === 'DELIVERED'
  const isRetrying = optimisticStatus === 'RETRYING'
  const userTriggeredRetry = optimisticStatus === 'USER_INITIATED_RETRY'

  const mappedStatus = webhookStatusMap[optimisticStatus] || optimisticStatus

  return (
    <>
      <div className="py-lg flex space-x-md">
        <div className="mt-xs">
          {isDelivered ? (
            <CheckmarkIcon aria-label={`Event ${mappedStatus}`} className="text-green" width="1.2rem" height="1.2rem" />
          ) : isRetrying ? (
            <TimeIcon aria-label={`Event ${mappedStatus}`} width="1.2rem" height="1.2rem" />
          ) : userTriggeredRetry ? (
            <RetryIcon aria-label={`Event ${mappedStatus}`} className="text-blue-dark" width="1.2rem" height="1.2rem" />
          ) : (
            <AlertIcon aria-label={`Event ${mappedStatus}`} className="text-red" width="1.2rem" height="1.2rem" />
          )}
        </div>

        <div className="flex flex-col w-[18rem]">
          <ClickToCopySection label="Webhook event ID" textToCopy={eventId}>
            <span className="sr-only">Event ID:</span>
            <span className="text-sm p-sm font-bold">{eventId}</span>
          </ClickToCopySection>

          <div
            className={`ml-sm font-bold text-sm mt-xs ${
              isRetrying ? 'text-black' : userTriggeredRetry ? 'text-blue-dark' : 'text-red'
            }`}
          >
            {!isDelivered && (
              <>
                <span>{mappedStatus}</span>
                {!userTriggeredRetry && (
                  <>
                    {' '}
                    <span>due to {getReadableWebhookStatusDetail(statusDetail)}</span>
                  </>
                )}
              </>
            )}
          </div>

          {events.length > 1 && (
            <div className="font-mono text-sm ml-sm">
              <span className="sr-only">Event type:</span>
              {eventType}
            </div>
          )}
        </div>

        {optimisticStatus === 'FAILED' && (
          <div className="flex-1">
            <RetryWebhookDelivery
              event={event}
              environment={environment}
              onComplete={() => {
                ampli.retryWebhookDelivery()
                setOptimisticStatus('USER_INITIATED_RETRY')
              }}
            />
          </div>
        )}

        <div className="flex-1 flex flex-col items-end justify-start space-y-sm">
          <div className="text-sm">
            <span className="sr-only">Last updated at:</span>
            <span>{new Date(statusUpdatedAt).toLocaleString()}</span>
          </div>

          <button
            id={accordionTitleId}
            aria-label={`${showEventPayload ? 'Hide' : 'Show'} payload for event ID: ${eventId}`}
            aria-expanded={showEventPayload}
            aria-controls={accordionContentId}
            className="text-left transition active:scale-[0.98] font-bold border-b border-blue-dark text-sm"
            onClick={() => {
              if (showEventPayload) {
                setShowEventPayload(false)
              } else {
                ampli.showWebhookEventPayload()
                setShowEventPayload(true)
              }
            }}
          >
            {showEventPayload ? 'Hide payload' : 'Show payload'}
          </button>
        </div>
      </div>

      <div id={accordionContentId} role="region" aria-labelledby={accordionTitleId}>
        <AnimatePresence key={`payload-${eventId}`}>
          {showEventPayload && (
            <motion.pre className="relative text-sm bg-grey-light rounded-b-half p-lg" {...expandAnimation}>
              <span className="absolute top-md right-md">
                <ClickToCopySection label="Webhook event payload" textToCopy={JSON.stringify(JSON.parse(payload))}>
                  <CopyIcon width="1.5rem" height="1.5rem" />
                </ClickToCopySection>
              </span>
              <code>{JSON.stringify(JSON.parse(payload), null, 2)}</code>
            </motion.pre>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}
