import React, { FunctionComponent, useEffect, useState } from 'react'
import type { Fetcher } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { expandAnimation } from '../../../shared/animationVariants'

export const FetcherError: FunctionComponent<{ fetcher: Fetcher }> = ({ fetcher }) => {
  const [error, setError] = useState('')

  useEffect(() => {
    if (!fetcher.data?.error) {
      return
    }

    setError(fetcher.data?.error)
    const id = setTimeout(() => setError(''), 3000)

    return () => clearTimeout(id)
  }, [fetcher])

  return (
    <AnimatePresence key="errorMsg">
      {error ? (
        <motion.div className="font-bold text-red pt-md" {...expandAnimation}>
          {error}
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
