import React, { FunctionComponent, useState } from 'react'
import { Dialog } from '../../../shared/Dialog'
import { CopyTextField } from '../common/CopyTextField'

const noOp = () => {}

type Props = {
  secret: string
  onClose: () => void
}

export const WebhookSecretDialog: FunctionComponent<Props> = ({ secret, onClose }) => {
  const [safelyStored, setSafelyStored] = useState<boolean>(false)

  return (
    <Dialog labeledBy="webhookDialogTitle" describedBy="webhookDialogDescription" onClose={() => {}}>
      <div className="card min-w-screen-sm">
        <div className="p-xl flex flex-col space-y-xl">
          <div className="prose">
            <h1 id="webhookDialogTitle">Webhook secret</h1>
            <p id="webhookDialogDescription">
              This secret is needed to confirm the authenticity of the webhook message.
            </p>
          </div>

          <CopyTextField value={secret} label="Webhook secret" />

          <p className="prose">
            <strong>This secret will never be shown again, so it is important that you store it somewhere safe!</strong>
          </p>
        </div>

        <div className="px-xl py-lg flex justify-between bg-grey-light rounded-b-half">
          <div className="flex items-center space-x-sm">
            <label className="select-none">
              <input
                type="checkbox"
                className="checkbox mr-sm"
                checked={safelyStored}
                onChange={() => setSafelyStored(!safelyStored)}
              />
              I have safely stored this
            </label>
          </div>

          <button
            className="btn"
            aria-disabled={!safelyStored}
            disabled={!safelyStored}
            onClick={!safelyStored ? noOp : onClose}
          >
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )
}
