import React, { useEffect, useState } from 'react'
import { TermsAndConditions } from '../../terms-and-conditions/TermsAndConditions'
import { acceptedTermsOfService } from '../../terms-and-conditions/termsOfServiceHelper'
import { Dialog } from '../../shared/Dialog'

export const ViewTermsAndConditions = () => {
  const [acceptedTerms, setAcceptedTerms] = useState<Date | null>(null)
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)

  useEffect(() => {
    acceptedTermsOfService().then(isoDate => setAcceptedTerms(isoDate))
  }, [])

  const closeTerms = () => setShowTermsAndConditions(false)

  return (
    <>
      {acceptedTerms && (
        <p className="text-sm font-bold">
          <button className="link" onClick={() => setShowTermsAndConditions(!showTermsAndConditions)}>
            View terms (accepted {acceptedTerms.toLocaleDateString()})
          </button>
        </p>
      )}

      {showTermsAndConditions && (
        <Dialog labeledBy="termsAndConditionsTitle" describedBy="termsAndConditionsDescription" onClose={closeTerms}>
          <div className="h-[100dvh] flex flex-col justify-between lg:max-h-[70dvh]">
            <div className="flex-1 relative bg-white overflow-scroll min-w-screen lg:min-w-screen-md lg:rounded-t-half">
              <TermsAndConditions />
            </div>
            <div className="bg-grey-light w-full pl-md pr-md py-md lg:rounded-b-half lg:flex lg:items-center lg:justify-end lg:pl-xl">
              <button type="button" className="btn bg-white w-full lg:w-10" onClick={closeTerms}>
                Close
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
