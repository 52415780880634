import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { MarkdownDoc } from './shared/MarkdownDoc'
import { PageNotFoundPage } from './pages/PageNotFoundPage'
import { AuthenticatedNavBar } from './navigation/AuthenticatedNavBar'
import { ApiDocPage } from './pages/api-doc/ApiDocPage'
import { getApiDoc, getApiProducts, getDocs, getProduct } from '../services/docs-service'
import { AuthenticatedAppLayout } from './AuthenticatedAppLayout'
import { AccountRoutes } from './pages/account/AccountRoutes'
import { UnknownErrorWithReload } from './shared/UnknownErrorWithReload'
import { hasDeveloperPortalLicense } from '../lib/authentication'
import { RequestAccessPage } from './RequestAccessPage'

const RootErrorElement = () => {
  const hasAccess = hasDeveloperPortalLicense()

  return (
    <div className="flex flex-col w-full">
      <AuthenticatedNavBar />
      {!hasAccess ? <RequestAccessPage /> : <UnknownErrorWithReload reloadPath="/" />}
    </div>
  )
}

export const AuthenticatedAppRoutes = () => {
  const authenticatedRouter = createBrowserRouter([
    {
      id: 'root',
      element: <AuthenticatedAppLayout />,
      loader: getApiProducts,
      errorElement: <RootErrorElement />,
      children: [
        {
          path: '',
          element: <MarkdownDoc />,
          loader: getDocs,
          errorElement: <UnknownErrorWithReload reloadPath="/" />
        },
        {
          path: 'product/:product',
          element: <MarkdownDoc pageTitle="Products" />,
          loader: getProduct,
          errorElement: <UnknownErrorWithReload reloadPath="/" />
        },
        {
          path: 'api/:api',
          loader: getApiDoc,
          element: <ApiDocPage />,
          errorElement: <UnknownErrorWithReload reloadPath="/" />
        },
        AccountRoutes,
        {
          path: '*',
          element: <PageNotFoundPage />
        }
      ]
    }
  ])

  return (
    <RouterProvider
      router={authenticatedRouter}
      fallbackElement={
        <div className="centerAbsolute">
          <div className="loadingSpinner" />
        </div>
      }
    />
  )
}
