import React from 'react'
import { RegionSelector } from '../pages/home/RegionSelector'
import { NavigationBar } from './NavigationBar'

export const HomeNavBar = () => {
  return (
    <NavigationBar>
      <RegionSelector />
    </NavigationBar>
  )
}
