import { NavLink, useLocation } from 'react-router-dom'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApiProductsData, RequestStatus } from '../../types'
import { DropDown } from './dropdown'
import { LogoutIcon } from '../icons'

type Props = {
  apiProducts: ApiProductsData
  logoutStatus: RequestStatus
  hasAccess: boolean
  logout: () => void
}

export const LargeScreenNavigation: FunctionComponent<Props> = ({ apiProducts, hasAccess, logoutStatus, logout }) => {
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState('')
  const dropDownMenuItemClasses = `w-full inline-block p-lg text-black font-bold transition-colors cursor-pointer rounded-half hover:bg-grey-light`

  const toggleIsOpen = (id: string) => {
    setIsOpen(isOpen === id ? '' : id)
  }

  useEffect(() => {
    setIsOpen('')
  }, [pathname])

  return (
    <>
      {hasAccess && apiProducts ? (
        <>
          <NavLink
            className={() =>
              pathname === '/' ? 'navtab col-span-2 3xl:col-span-1 active' : 'navtab col-span-2 3xl:col-span-1'
            }
            to="/"
          >
            Getting started
          </NavLink>

          <div className="col-span-2 3xl:col-span-1 h-full">
            <DropDown
              title="Products"
              path="/product"
              arrowColor="white"
              isOpen={isOpen === '/product'}
              setIsOpen={toggleIsOpen}
            >
              <ul>
                {apiProducts.products.length > 0 ? (
                  apiProducts.products.map(product => (
                    <li key={product.id}>
                      <NavLink
                        to={`/product/${product.id}`}
                        className={({ isActive }) =>
                          `${dropDownMenuItemClasses} ${isActive ? 'text-blue-dark' : 'text-black'}`
                        }
                      >
                        {product.name}
                      </NavLink>
                    </li>
                  ))
                ) : (
                  <li className="text-black">You don&apos;t have access to any products at the moment.</li>
                )}
              </ul>
            </DropDown>
          </div>

          <div className="col-span-2 3xl:col-span-1 h-full">
            <DropDown title="APIs" path="/api" arrowColor="white" isOpen={isOpen === '/api'} setIsOpen={toggleIsOpen}>
              <ul>
                {apiProducts.apis.length > 0 ? (
                  apiProducts.apis.map(api => (
                    <li key={api.id}>
                      <NavLink
                        to={`/api/${api.id}`}
                        className={({ isActive }) =>
                          `${dropDownMenuItemClasses} ${isActive ? 'text-blue-dark' : 'text-black'}`
                        }
                      >
                        {api.name}
                      </NavLink>
                    </li>
                  ))
                ) : (
                  <li className="text-black">You don&apos;t have access to any APIs at the moment.</li>
                )}
              </ul>
            </DropDown>
          </div>

          <NavLink
            className={({ isActive }) =>
              isActive ? 'navtab col-span-2 3xl:col-span-1 active' : 'navtab col-span-2 3xl:col-span-1'
            }
            to="/account"
          >
            Account
          </NavLink>
        </>
      ) : null}

      <button
        aria-label="Log out"
        className="btn btn-icon col-span-1 md:col-start-12 flex items-center justify-end"
        disabled={logoutStatus === 'pending'}
        onClick={logout}
      >
        {logoutStatus === 'pending' ? (
          <span role="progressbar" className="loadingSpinner" />
        ) : (
          <LogoutIcon color="white" width="2rem" height="2rem" />
        )}
      </button>
    </>
  )
}
