import { ApiResponse } from './ApiResponse'
import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { ErrorBoundary } from '../../../shared/ErrorBoundary'

type Props = {
  responses: OpenAPIV3_1.ResponsesObject | undefined
}

export const ApiResponses: FunctionComponent<Props> = ({ responses }) => {
  if (!responses) {
    return null
  }

  const responseTitles = Object.keys(responses)

  const successResponse = responseTitles.filter((response: string) => parseInt(response, 10) < 400)?.[0]
  const errorResponses = responseTitles.filter((response: string) => parseInt(response, 10) >= 400)

  return (
    <section className="flex flex-col space-y-sm">
      <h3 className="text-lg">Responses</h3>

      <ul>
        <ErrorBoundary>
          <ApiResponse
            key={successResponse}
            title={successResponse}
            response={responses[successResponse] as OpenAPIV3_1.ResponseObject}
          />
        </ErrorBoundary>

        {errorResponses.length > 0 && (
          <ErrorBoundary>
            {errorResponses.map((responseTitle: string) => (
              <ApiResponse
                key={responseTitle}
                title={responseTitle}
                response={responses[responseTitle] as OpenAPIV3_1.ResponseObject}
              />
            ))}
          </ErrorBoundary>
        )}
      </ul>
    </section>
  )
}
