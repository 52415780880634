import React, { FunctionComponent, useState } from 'react'
import { CopyTextField } from '../common/CopyTextField'
import { OAuthClient } from '../../../../types'
import { ampli } from '../../../../ampli'

type Props = {
  client: OAuthClient
}

export const OAuthClientSecret: FunctionComponent<Props> = ({ client }) => {
  const [showSecret, setShowSecret] = useState(false)

  return (
    <div className="relative w-full h-3 flex items-center space-x-md">
      {showSecret ? (
        <CopyTextField label="Client secret" value={client.secret as string} />
      ) : (
        <React.Fragment>
          <div className="w-full relative h-2 overflow-hidden">
            <div
              aria-label="Client secret"
              className="absolute top-0 leading-normal w-full h-2 flex items-center pl-xs mx-md"
            >
              ***********************************************
            </div>
            <div className="absolute top-0 w-full h-2 backdrop-blur" />
          </div>

          <button
            type="button"
            className="btn btn-secondary leading-normal py-xs px-md text-sm min-h-2 flex items-center"
            onClick={() => {
              if (showSecret) {
                setShowSecret(false)
              } else {
                setShowSecret(true)
                ampli.revealOAuthClientSecret()
              }
            }}
          >
            Reveal
          </button>
        </React.Fragment>
      )}
    </div>
  )
}
